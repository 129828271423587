// 生产环境
let config = {
  baseUrl: 'http://192.168.1.35:30001/gateway', // 网关地址，一般使用内网地址
  tokenName: 'ENT_WEB_TOKEN'
}

if (process.env.NODE_ENV === 'development') {
  // 开发环境
  config = {
    ...config,
    // baseUrl: 'http://192.168.1.33:12008/gateway' // 网关地址
    // baseUrl: 'https://pxy.demo.swsjtech.com/gateway' // 网关地址
    baseUrl: 'http://192.168.1.35:40001/gateway' // 网关地址
    // baseUrl: 'https://edu.caasbast.com/gateway' // 网关地址 华为云地址
  }
}
export default config
